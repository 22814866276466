import React, { useEffect, useState } from 'react'
import { Layout, theme } from 'antd';
import { Link, useParams } from 'react-router-dom'
import { BsCartDashFill } from "react-icons/bs"
import './ProductsCategoryItens.css'
// import Counter from '../../../common/counter/Counter';
// import product1 from '../../../assets/img/products/walkip1.jpg'
// import product2 from '../../../assets/img/products/walkip2.jpg'
// import product3 from '../../../assets/img/products/walkip3.jpg'
// import product4 from '../../../assets/img/products/walkip4.png'
import axios from 'axios';
import { base_url } from '../../../server';
import { Alert } from '@mui/material';
import Item from 'antd/es/list/Item';


const { Content } = Layout;
function ProductsCategoryItems() {
    // const [categ, setCateg] = useState(null)
    // const [brand, setBrand] = useState(null)
    const [sendState, setSendState] = useState(null)
    const [errors, setErrors] = useState(true)
    const params = useParams()
    const [filterState, setFilterState] = useState({
        categories: [],
        brands: [],
        minPrice: "0",
        maxPrice: "1000000",
        sort: '1'
    });
    
    // const getCategoryData = async () => {
    //     const responsecateg = await axios.get(`${base_url}category/public`, { withCredentials: true })
    //     setCateg(responsecateg.data)
    // }
    // const getBrandData = async () => {
    //     const responseBrand = await axios.get(`${base_url}brand/public`, { withCredentials: true })
    //     setBrand(responseBrand.data)
    // }
    // console.log(categ,'categ-----------------');
    // console.log(brand,'brand------------------------');

    const sendCategoryData = async () => {
        try {
            const response = await axios.post(`${base_url}product/filter`, { ...filterState, categories: [params?.id], brands: [] }, { withCredentials: true })
            setSendState(response.data)
            setErrors(false)
        } catch (error) {
            setErrors(error.message)
        }
    }
    useEffect(() => {
        // getCategoryData()
        // getBrandData()
        sendCategoryData()
        window.scrollTo({
            top:0,
            behavior:`smooth`
        })
    }, [params?.id])
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    return (
        <Content style={{ margin: '24px 16px 0' }}>
            {errors && <div className='text-center'>
                <Alert icon={false} variant='filled' severity="error" className='d-block' >
                    <h3 className='text-center'>{errors}</h3>
                </Alert>
            </div>}
            {sendState?.length > 0 && 
                <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                    <div className='row'>
                        {sendState && sendState?.map((item, i) => {
                            return <div className='col-md-4 col-lg-4 col-6' key={i}>
                                <div className='productsCard1 my-2'>
                                    <Link to={`/product-details/${item?.uid}/${item?.slug}`} className='py-3'>
                                        <figure className='text-center py-3'>
                                            <img src={item?.variations[0]?.mainImage_url?.url} alt='products' width={65 + '%'} />
                                        </figure>
                                    </Link>
                                    <div className='productTitle p-3'>
                                        <Link to={'/product-details'} className='text-decoration-none'>
                                            <div className='text-truncate' >
                                                <span className='text-dark text-hove-pos'>
                                                    <Link to={''} className='text-decoration-none text-truncate'>{item?.name}</Link>
                                                </span>
                                            </div>
                                        </Link>
                                        <div className='d-flex' id='price'>
                                            <span className='text-muted text-decoration-line-through ps-1' style={{ textDecoration: '' }}>AED {item?.prices?.sale_rate}</span>
                                            &nbsp;
                                            &nbsp;
                                            &nbsp;
                                            <span>AED {item?.prices?.mrp}</span>
                                            <div className='sci  d-flex' >
                                                {/* <div className='py-2' style={{ backgroundColor: "#80808087" }}>
                                            <Counter />
                                        </div> */}
                                                <span className='addToCart'>
                                                    <BsCartDashFill />
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        })}
                    </div>
                </div>
            }

        </Content >
    )
}

export default ProductsCategoryItems
