import React, { useEffect, useState } from 'react'
import industryBanner from "../../assets/img/industryBannerImg/first-page1.jpg"
import Breadcrums from '../../common/breadcrums/Breadcrums'
import bellButt from "../../assets/img/products/SB9-3xbk_01.png"
import bellButt0 from "../../assets/img/products/transmiterBtnBell1.jpg"
import bellButt1 from "../../assets/img/products/watch.png"
import bellButt2 from "../../assets/img/products/SG1.png"
import './industry.css'
import axios from 'axios'
import { base_url } from '../../server'
import { useParams } from 'react-router-dom'

function Industry() {
  const params = useParams()
  const [state, setState] = useState(null)
  const [errors, setErrors] = useState(true)
  const [indusData, setindusData ] = useState(null)
  const [message, setErrorMessage] = useState(null)
  const [filterState, setFilterState] = useState({
    industries: [
      params.uid
    ],
    categories: [],
    brands: [],
    minPrice: "0",
    maxPrice: "1000000",
    sort: '1'
  });
  const sendCategoryData = async () => {
    try {
      const response = await axios.post(`${base_url}product/filter`, { ...filterState, }, { withCredentials: true })
      setState(response.data)
      setErrors(false)
    } catch (error) {
      setErrors(error.message)
    }
  }
  const getIndustryDetails = async (id) => {
    try {
      const response = await axios.get(`${base_url}industry/${id}`, { withCredentials: true })
      setindusData(response.data)
    } catch (error) {
      setErrorMessage(error?.message)
    }

  }
  useEffect(() => {
    sendCategoryData()
    getIndustryDetails(params?.uid)
  }, [params?.uid])

console.log(indusData)
  const breadcrumsTitle = {
    id: '1', title: 'INDUSTRY'
  }
  return (
    <div>
      <Breadcrums breadcrumsTitle={breadcrumsTitle} />
      <section>
        <div className='container'>
          <div className='row my-3'>

            <div className='col-md-6 col-sm-12 col-12   rounded'>
              <img src={indusData?.banner?.url} alt='industryBanner' width={100 + "%"} />
            </div>
            <div className='col-md-6 col-sm-12 col-12  rounded'>
              <h4><b>RESTAURANTS WIRELESS PAGING SYSTEM SOLUTIONS </b></h4>
              <div className='px-3'>
                <h5>Restaurant Calling System</h5>
                <ul>
                  <li>Customers press the call button, the table number will be shown on the receiver.</li>
                  <li>Waiter is able to figure that which tables need service.</li>
                </ul>
                <h5>Wireless Guest Paging System</h5>
                <ul>
                  <li>Hand over the guest pager to customers after ordering.</li>
                  <li>When order is ready, call them and get the guest pager back.</li>
                </ul>
                <h5>Wireless Server Paging System</h5>
                <ul >
                  <li>When food or drink is ready, chef/bartender will call servers.</li>
                  <li>Server is able to notice where to pick the food. (Kitchen or Bar)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='relHeading text-center'>
            <h3 className='section-title'>RELATED PRODUCTS</h3>
          </div>
          <div className='my-4'>
            <div className='row'>
              {state?.map((item, i) => {
                console.log(item?.variations[0]?.images[0].url);
                return <div className='col-md-3 col-12'>
                  <div className="product-card">
                    <div className="badge">New</div>
                    <div className="product-tumb">
                      <img src={item?.variations[0]?.images[0].url} alt="bellButton" width={100 + "%"} />
                    </div>
                    <div className="product-details">
                      <h4><a href="">3 Button Bell</a></h4>
                      <p>New 3button transmitter-Three call functions (Service, Bill, Cancel)</p>
                    </div>
                  </div>
                </div>
              })}

              <div className='col-md-3 col-12'>
                <div className="product-card">
                  <div className="badge">New</div>
                  <div className="product-tumb">
                    <img src={bellButt} alt="bellButton" width={100 + "%"} />
                  </div>
                  <div className="product-details">
                    <h4><a href="">3 Button Bell</a></h4>
                    <p>New 3button transmitter-Three call functions (Service, Bill, Cancel)</p>
                  </div>
                </div>
              </div>

              <div className='col-md-3 col-12'>
                <div className="product-card">
                  <div className="badge">New</div>
                  <div className="product-tumb">
                    <img src={bellButt0} alt="bellButton" width={100 + "%"} />
                  </div>
                  <div className="product-details">
                    <h4><a href="">WATER RESISTANCE BELL</a></h4>
                    <p>The newest water-resistant call button with slim and attractive design</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-12'>
                <div className="product-card">
                  <div className="badge">New</div>
                  <div className="product-tumb">
                    <img src={bellButt1} alt="bellButton" width={100 + "%"} />
                  </div>
                  <div className="product-details">
                    <h4><a href="">DIRECT PAGER
                    </a></h4>
                    <p>This direct pager, SP7-100, is the direct pager which doesn’t require an extra monitor since it has receiver module embedded.</p>
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-12'>
                <div className="product-card">
                  <div className="badge">New</div>
                  <div className="product-tumb">
                    <img src={bellButt2} alt="bellButton" width={100 + "%"} />
                  </div>
                  <div className="product-details">
                    <h4><a href="">GUEST PAGER
                    </a></h4>
                    <p>Give the guest pager to the waiting customers, call the pager when is ready to serve.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Industry
