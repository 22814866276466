import React from 'react'
import "./App.css"
import { Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import TopHeader from "./common/topHeader/TopHeader";
import Navigation from './common/navigation/Navigation';
import HomesPages from './pages/home';
import Footer from './common/footer/Footer';
import ContactsByEmail from './components/home/contactByEmail/ContactsByEmail';
import CategoryPages from './pages/categorey';
import ProductDetails from './components/productDetails/ProductDetails';
import OemRequest from './pages/contectUs';
import Blogs from './components/blogs/Blogs';
import AboutusPage from './pages/about';
import PrivacyPolicy from './components/privacyPolicy/PrivacyPolicy';
import TermAndConditions from './components/termAndConditions/TermAndConditions';
import BlogDetails from './components/blogs/blogDetails/BlogDetails';
import Faq from './components/faqs/Faq';
import Registeredpage from './pages/registered/Index.';
import LoginPage from './pages/login';
import IndustryPage from './pages/industry';
function App() {
  return (
    <>
      <TopHeader/>
      <Navigation/>
      <Routes>
        <Route path='/' element={<Navigate  to="/home" />}/>
        <Route path='/register' element={<Registeredpage/>}/>
        <Route path='/login' element={<LoginPage/>}/>
        <Route path='/home' element={<HomesPages/>}/>
        <Route path='/category/:id/:slug' element={<CategoryPages/>}/>
        <Route path='/about-us' element={<AboutusPage/>}/>
        <Route path='/product-details/:uid/:slug' element={<ProductDetails/>}/>
        <Route path='/industry/:uid/:slug' element={<IndustryPage/>}/>
        <Route path='/oem-request' element={<OemRequest/>}/>
        <Route path='/blogs' element={<Blogs/>}/>
        <Route path='/blog-details/:uid/:_id' element={<BlogDetails/>}/>
        <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
        <Route path='/term-condition' element={<TermAndConditions/>}/>
        <Route path='/faqs' element={<Faq/>}/>
      </Routes>
      <ContactsByEmail/>
      <Footer/> 
    </>
  );
}

export default App;
