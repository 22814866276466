import React, { useEffect, useState } from 'react'
import axios from 'axios'
import "./BannerWrpper.css"
import { Carousel } from 'antd';
import { base_url } from '../../../server'
import { Alert, Button } from '@mui/material';



function BannerWrpper() {
    const [bannerData, setBannerData] = useState(null)
    const [errorMessage, setErrorMessage] = useState(false)
    const getBannerData = async () => {
        try {
            const response = await axios.get(`${base_url}banner/public`, { withCredentials: true })
            setBannerData(response.data);
            setErrorMessage(false)

        } catch (error) {
            setErrorMessage(error.message)
        }

    }
    
    useEffect(() => {
        getBannerData()
    }, [])
    return (
        <section className=''>
            {errorMessage &&
                <div className='text-center'>
                    <Alert icon={false} variant='filled' severity="error" className='d-block' >
                        <h2 className='text-center'>{errorMessage}</h2>
                    </Alert>
                </div>
            }
            <Carousel  autoplay >
                {
                    bannerData?.map((bannItem, i) => {
                        return <div className="carousel-item active" key={i} >
                            <div className='d-flex justify-content-center align-items-center text-light p-2 text-uppercase ' style={{ backgroundImage: `url(${bannItem?.image?.url})`,minHeight : "65vh", backgroundRepeat: 'no-repeat'}}>
                                <div className='text-center'>
                                <h3>{bannItem?.SliderTopHeading}</h3>
                                <Button variant='outlined' className='text-light'>{bannItem?.bottomText}</Button>
                                </div>
                            </div>
                        </div>
                    })
                }
            </Carousel>
        </section>
    )
}

export default BannerWrpper
