import React from 'react'
import Industry from '../../components/industry/Industry'

function IndustryPage() {
  return (
    <>
      <Industry/>
    </>
  )
}

export default IndustryPage
